import React from 'react';
import { Link, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image'

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout';
import CallToAction from '../components/CallToAction';

import './mystyles.scss';

const IndexPage = ({ data, pageContext }) => {

  const posts = data.allMdxPosts.nodes;
  const offers = data.allMdxOffers.nodes;

  const [checked, setChecked] = React.useState(String);
  
  return (
    <Layout heroTitle="Mit Sicherheit ein guter Partner" heroSubtitle="Die Experten der zentrust partners" imageData={data.heroImage.childImageSharp.gatsbyImageData} pageContext={pageContext}>

      <section className="section">
        <div className="columns">
          <div className="column">
            
            {/* Intro */}
            <div className="block">
              <div className="container">
              
                <h1 className="title">Wenn es um die Sicherheit Ihrer Daten, Anwendungen oder Infrastruktur geht...</h1>
                <span className="subtitle">...sollten Sie nichts dem Zufall überlassen. Die Experten von <i>zentrust partners</i> helfen Ihnen bedarfsgerecht und zielgerichtet, potenzielle Risiken und Sicherheitsmängel aufzudecken. Profitieren Sie von unserer langjährigen Erfahrung in der <b>Durchführung von Penetrationstests (Pentest) und Sicherheitsberatungen</b>. Durch die Kombination aus fundierter Branchenkenntnis und einem Blick für das Wesentliche machen wir IT-Sicherheit auf höchstem Niveau für Sie greifbar.</span>
 
                {/*
                  Feedback Elli:
                  Nicht direkt genug, zu viele Buzzwords, keine Aussage. 
                  Einstieg: Etwas was klar macht, wenn wir ein Leck haben dann schadet es meiner Marktposition (Daten, Verantwortung,..). 
                  Argumentation immer aus der Perspektive des Kunden, Weg von der Selsbtdarstellung. 
                  Wir müssen den Kunden da abholen wo er steht.
                  Zu abgehoben nichts für Mittelständler (Formulierungen wie Reifegrad...).
                  Hohe Anforderungen an die Informationssicherheit, Komplexität, wir haben ein Netzwerk aus Expterten welche die Bedürfnisse des Kunden abdecken.

                  Vorschlag Sven:
                  Digitale Bedrohungen aus aller Welt, unsichere Software, ungeschulte Mitarbeiter und vieles mehr bedrohen die Sicherheit der Informationen welche Ihrem Unternehmen anvertraut wurden.
                  Sie stehen in der Verantwortung die Daten zu schützen und regulatorische Anforderungen zu erfüllen. Ein Mangel in Ihrer Verteidigung gefährdet Ihre Marktposition.
                  Wir die ZFT.COMPANY stehen Ihnen mit unserem renomierten Team aus Experten zur Seite um diese Herausforderungen zu bewältigen.
                */}
                {/*
                <div className="is-flex is-flex-wrap-wrap" style={{gap: "1rem"}}>
                  <Link className="button offer-button" to="/angebot/pentest">Pentest <span className="triangle-right"></span></Link>
                  <Link className="button offer-button" to="/angebot/secure-code-review/">Secure-Code-Review <span className="triangle-right"></span></Link>
                  <Link className="button offer-button" to="/angebot/sicherheitskonzept">Sicherheitskonzept <span className="triangle-right"></span></Link>
                  <Link className="button offer-button" to="/angebot/identitaetsmanagement">Identitätsmanagement <span className="triangle-right"></span></Link>
                  <Link className="button offer-button" to="/angebot/ot-sicherheit">OT-Sicherheit <span className="triangle-right"></span></Link>
                  <Link className="button offer-button" to="/angebot">…<span className="triangle-right"></span></Link>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="section slant-top slant-bottom" style={{background:"#f2f2f2"}}>
        <div className="columns">
          <div className="column">
          
            {/* Unsere Leistungen 
              
              
              Organisation
              * 
              
              Cloud & Infrastruktur
              * OK Risikomanagement
              * OK Sicherheitsanalyse
              * Architectural Design Review
              * Simulation eines externen Angriffs
              * Simulation eines internen Angriffs
              * OK Container und Orchestrierung
              * OK Red-Team
              
              Apps, Anwendungen & Produkte
              * OK Anwendungs- und Produktpenetrationstest
              * OK Review des Security Designs der Anwendung / Threat Modeling
              * OK Code Review einer Anwendung
              * Fuzz Testing einer Anwendung
              * Security in Software Development Lifecycle (SSDLC)
              * DAST/SAST
              * OK Beurteilung von Mobilanwendungen
              * OK Hardware & Embedded Systems
              
              Ransomware & Incident Response
              * Ransomware Simulation

              Consulting
              * 
              * 
              
              Workshops & Fortbildung
              * OK Threat Modeling
              * OK Security Best Practices für Entwickler (Android- / iOS)
              * Mobile Apps vor Reverse Engineering schützen
              
              
              Mängelbehebung
              * Triage und Priorisierung
              * Begleitung Beseitigung und Verbesserung
              * Retests
              
              
              */}
              
            <div className="block">
              <div className="container">
                <h1 className="title">Unser Angebot</h1>
                
                <div className="columns is-multiline is-full is-justify-content-space-between">
                {
                  offers.map((offer) => {
                    const backgroundImageStack = [
                      `radial-gradient(at 5% top, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.9))`
                    ];
                    const imageData = offer.frontmatter.hero_image.childImageSharp.fluid;
                    if (imageData) backgroundImageStack.push(imageData);
                    
                    return (
                      <div className="column is-flex ">
                        <Link to={`/${offer.slug}`} className="box is-flex-grow-1">

                          <div className="subtitle mb-2">
                             <span className="">{offer.frontmatter.title}</span>
                          </div>
                          <div className="content mb-2">
                            {offer.frontmatter.description}
                          </div>
                          {
                            offer.excerpt && <Link to={`/${offer.slug}`} className="link offer-button">
                              Mehr erfahren...
                            </Link>
                          }
                        </Link>
                      </div>
                    );
                  })
                }
                </div>
                
                <p>Alle angebotenen Leistungen finden Sie <Link to="/leistungen">in unserer Übersicht</Link>.</p>
              </div>
            </div>
            
            {/*
            <div className="block">
              <div className="container">
                <h1 className="title">Unser Angebot</h1>

                <div className="tile is-ancestor">
                {
                  offers.map((offer) => {
                    
                    return (
                      <div className="tile is-parent">
                        <Link to={`/${offer.slug}`} className="tile is-child card blog-card is-flex is-flex-direction-column" sstyle={{border:"#dbdbdb 1px solid", display:"flex", flexDirection:"column"}}>
                          <div className="card-image">
                            
                            <BackgroundImage
                              Tag="div"
                              className="is-flex is-flex-direction-column is-justify-content-space-between"
                              style={{padding: "1.5rem 1.5rem 1.5rem 1.5rem", height: "8rem"}}
                              {...convertToBgImage(offer.frontmatter.hero_image.childImageSharp.gatsbyImageData)}
                            >
                              <div className="">
                                
                               
                                    <span className="has-text-white subtitle" style={{margin:"0.15rem"}}>{offer.frontmatter.title}</span>
                                  
                               </div>
                            </BackgroundImage>
                            
                          </div>
                          <div class="card-content is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-space-between">
                            <div className="content" style={{wordWrap:"break-word",hyphens:"auto"}}>
                              {offer.excerpt}
                            </div>

                          </div>
                        </Link>
                      </div>
                    );
                  })
                }
                </div>
                
              </div>
            </div>
            */}
            
            {/*
            <div className="block" >
              <div className="container">
                <h1 className="title">Unser Angebot</h1>
                
                <div className="columns is-multiline">
                
                {
                  offers.map((offer) => {
                    const backgroundImageStack = [
                      `radial-gradient(at 5% top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9))`
                    ];
                    const imageData = offer.frontmatter.hero_image.childImageSharp.gatsbyImageData;
                    if (imageData) backgroundImageStack.push(imageData);
                    
                    return (
                      <div className="column is-4">
                        <div className="card industry-card" style={{minWidth: "10em"}}>
                        <Link to={`/${offer.slug}`}>
                          <header className="card-header" onClick={() => setChecked(offer.frontmatter.title)}>
                            <span className="card-header-title subtitle">
                              <StaticImage src="../images/person.svg" alt={offer.frontmatter.title} layout="fullWidth" className="industry-card-logo"/>
                              <span className="industry-card-title">{offer.frontmatter.title}</span><span className="triangle-bottom is-hidden-tablet"/>
                            </span>
                          </header>

                          <div className={`card-content ${checked === offer.frontmatter.title ? "active" : ""}`}>
                            <div className="content">
                              {offer.excerpt}
                            </div>
                          </div>
                        </Link>
                        </div>
                      </div>
                    );
                  
                  })
                }
               
                </div>
              </div>
            </div>
            */}
            
          </div>
        </div>
      </section>
      
      {/*
      <section className="section slant-top slant-bottom" style={{background:"#f2f2f2"}}>
        <div className="container">
          <h1 className="title">Unsere Kompetenzen</h1>
          <div className="columns is-multiline is-mobile is-justify-content-center">
            <div className="column is-flex-grow-0">
              <StaticImage src="../images/computer.svg" alt="Softwareentwicklung" layout="fullWidth" className="image is-128x128 mb-2"/>
              <div className="title mb-2">&gt; 100</div>
              <div>Projekte im Jahr</div>
            </div>
            
            <div className="column is-flex-grow-0">
              <StaticImage src="../images/computer.svg" alt="Softwareentwicklung" layout="fullWidth" className="image is-128x128 mb-2"/>
              <div className="title mb-2">&gt; 100</div>
              <div>zufriedene Kunden</div>
            </div>
            
            <div className="column is-flex-grow-0">
              <StaticImage src="../images/computer.svg" alt="Softwareentwicklung" layout="fullWidth" className="image is-128x128 mb-2"/>
              <div className="title mb-2">24 / 7</div>
              <div>Einsatzzeiten</div>
            </div>
          </div>
        </div>
      </section>
      */}
      
      <section className="section">
        <div className="columns">
          <div className="column">
            
            {/* Blog-Roll */}
            <div className="block">
              <div className="container">
                <h1 className="title">Unsere Insights</h1>
                {/*
                  Feedback Elli: 
                  Das kann ich, damit habe ich mich beschäftigt. Statt neutral "Beiträge unserer Experten". 
                  Vielleicht die Leuchtturm-Thematik aufgreifen. 
                  Insights. 
                  Es muss klar sein, damit haben die sich beschäftigt -> Aha damit kennen Sie sich aus. 
                  Unsere Expertise. Unser Know-How. 
                  Evtl. Blog-Roll durch Referenzen ersetzen. 
                */}
                <div className="tile is-ancestor">
                {
                  posts.map((node) => {
                    
                    return (
                      <div className="tile is-parent">
                        <Link to={`/${node.slug}`} className="tile is-child card blog-card is-flex is-flex-direction-column" sstyle={{border:"#dbdbdb 1px solid", display:"flex", flexDirection:"column"}}>
                          <div className="card-image">
                            
                            <BackgroundImage
                              Tag="div"
                              className="is-flex is-flex-direction-column is-justify-content-space-between"
                              style={{padding: "1.5rem 1.5rem 1.5rem 1.5rem", height: "8rem"}}
                              {...convertToBgImage(node.frontmatter.hero_image.childImageSharp.gatsbyImageData)}
                            >
                              <div className="is-align-self-flex-end" style={{margin:"-0.15rem", marginTop: "auto", textAlign:"right"}}>
                                {node.frontmatter.tags.map((tag) => (
                                  <Link to={`/blog/tags/${tag}/`}>
                                    <span className="tag is-uppercase is-light" style={{margin:"0.15rem"}}>{tag}</span>
                                  </Link>
                                 ))}
                               </div>
                            </BackgroundImage>
                            
                          </div>
                          <div class="card-content is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-space-between">
                            <div className="content" style={{wordWrap:"break-word",hyphens:"auto"}}>
                              {node.frontmatter.title}
                            </div>

                          </div>
                        </Link>
                      </div>
                    );
                  })
                }
                </div>
                
                <p>Viele weitere Beiträge finden Sie <Link to="/blog">in unserem Blog</Link>.</p>
              </div>
            </div>

          </div>
        </div>
      </section>
      
      <section className="section slant-top slant-bottom" style={{background:"#f2f2f2"}}>
        <div className="columns">
          <div className="column">
          
            {/* Übersicht der Branchen */}
            <div className="block">
              <div className="container">
                <h1 className="title">Branchenkenntnis</h1>
                
                <p>Zur Erreichung Ihrer Sicherheitsziele berücksichtigen wir nicht nur die aktuelle Bedrohungslage, sondern unsere Experten verfügen auch über branchenspezifisches Wissen und kennen die regulatorischen Vorgaben.</p>
                
                <div className="columns is-multiline">
                  <div className="column is-4">

                    <div className="card industry-card">
                      <header className="card-header" onClick={() => setChecked("software")}>
                        <span className="card-header-title subtitle">
                          <StaticImage src="../images/computer.svg" alt="Softwareentwicklung" layout="fullWidth" className="industry-card-logo"/>
                          <span className="industry-card-title">Softwareentwicklung</span><span className="triangle-bottom is-hidden-tablet"/>
                        </span>
                      </header>

                      <div className={`card-content ${checked === "software" ? "active" : ""}`}>
                        <div className="content">
                          Über den gesamten Software-Development-Life-Cycle sind wir für Sie da. Von der Bedrohungsmodellierung in der Design- und Konzeptionsphase über den Secure-Code-Review während der Entwicklung bis hin zum Penetrationstest (Pentest) und einem Cloud-Security-Review nach erfolgter Implementierung.
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="column is-4">

                    <div className="card industry-card">
                      <header className="card-header" onClick={() => setChecked("insurance")}>
                        <span className="card-header-title subtitle">
                          <StaticImage src="../images/umbrella.svg" alt="Versicherungen" layout="fullWidth" className="industry-card-logo"/>{" "}
                          <span className="industry-card-title">Versicherungen</span><span className="triangle-bottom is-hidden-tablet"/>
                        </span>
                      </header>

                      <div className={`card-content ${checked === "insurance" ? "active" : ""}`}>
                        <div className="content">
                           Die Digitalisierung hat das Versicherungsgeschäft verändert, regulatorische Vorgaben bis hin zum IT-Sicherheitsgesetz folgen. Wir begleiten Sie bei der sicheren Umsetzung.
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="column is-4">

                    <div className="card industry-card">
                      <header className="card-header" onClick={() => setChecked("health")}>
                        <span className="card-header-title subtitle">
                          <StaticImage src="../images/health.svg" alt="Gesundheitswesen" layout="fullWidth" className="industry-card-logo"/>
                          <span className="industry-card-title">Gesundheitswesen</span><span className="triangle-bottom is-hidden-tablet"/>
                        </span>
                      </header>

                      <div className={`card-content ${checked === "health" ? "active" : ""}`}>
                        <div className="content">
                          Sie planen oder Entwickeln ein Medizinprodukt, eine DiGA oder DiPA? Sie suchen einen Weg durch regulatorische Vorgaben? Unsere Experten im Bereich E-Health weisen Ihnen den Weg.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="column is-4">

                    <div className="card industry-card">
                      <header className="card-header" onClick={() => setChecked("infrastructure")}>
                        <span className="card-header-title subtitle">
                          <StaticImage src="../images/person.svg" alt="Staatliche Infrastrukturen" layout="fullWidth" className="industry-card-logo"/>
                          <span className="industry-card-title">E-Government</span><span className="triangle-bottom is-hidden-tablet"/>
                        </span>
                      </header>

                      <div className={`card-content ${checked === "infrastructure" ? "active" : ""}`}>
                        <div className="content">
                          Im E-Government gelten eigene Gesetze. Von Identitätsmanagement bis zur Wahlauswertungssoftware sind unsere Experten mit Ihren Sicherheitsanforderungen vertraut.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="column is-4">

                    <div className="card industry-card">
                      <header className="card-header" onClick={() => setChecked("finance")}>
                        <span className="card-header-title subtitle">
                          <StaticImage src="../images/finance.svg" alt="Finanzen" layout="fullWidth" className="industry-card-logo"/>
                          <span className="industry-card-title">Finanzwesen</span><span className="triangle-bottom is-hidden-tablet"/>
                        </span>
                      </header>

                      <div className={`card-content ${checked === "finance" ? "active" : ""}`}>
                        <div className="content">
                          Ob Fintech, Neobank oder traditionellem Bankengeschäft, alle notwendigen sowie regulatorische Sicherheitsanforderungen erfüllen Sie mit Unterstützung unserer Experten.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="column is-4">

                    <div className="card industry-card">
                      <header className="card-header" onClick={() => setChecked("others")}>
                        <span className="card-header-title subtitle">
                          <StaticImage src="../images/lightbulb.svg" alt="Weitere Branchen" layout="fullWidth" className="industry-card-logo"/>
                          <span className="industry-card-title">Weitere Branchen</span><span className="triangle-bottom is-hidden-tablet"/>
                        </span>
                      </header>

                      <div className={`card-content ${checked === "others" ? "active" : ""}`}>
                        <div className="content">
                          Von Mobilität über E-Commerce bis Verlagswesen haben wir Experten mit branchenspezifischer Erfahrung für Sie mit an Bord.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          
          </div>
        </div>
      </section>
      
      <section className="section slant-top">
        <div className="columns">
          <div className="column">
          
            {/* In der Medien */}
            <div className="block">
              <div className="container">
                <h1 className="title">In den Medien</h1>

                <p>Unsere Experten sind gefragte Ansprechpartner und teilen gerne ihre fachkundige Meinung in den Medien.</p>
                
                
                <div className="tile is-ancestor">
                {
                  data.allMdxMedia.nodes.map((node) => {
                    
                    return (
                      <div className="tile is-parent">
                        <a href={`${node.frontmatter.url}`} className="tile is-child card blog-card is-flex is-flex-direction-column" target="_blank" rel="noreferrer">
                          <div className="card-image">
                            
                            <BackgroundImage
                              Tag="div"
                              className="is-flex is-flex-direction-column is-justify-content-space-between"
                              style={{padding: "1.5rem 1.5rem 1.5rem 1.5rem", height: "8rem"}}
                              {...convertToBgImage(node.frontmatter.hero_image.childImageSharp.gatsbyImageData)}
                            >
                            </BackgroundImage>
                            
                          </div>
                          <div class="card-content is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-space-between">
                            <div className="content" style={{wordWrap:"break-word",hyphens:"auto"}}>
                              {node.frontmatter.title}
                            </div>

                          </div>
                        </a>
                      </div>
                    );
                  })
                }
                </div>
              
                <p>
                Alle Referenzen <Link to="/partner#media">in unserer Medien-Übersicht</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <CallToAction title="Was können wir für Sie tun?" subtitle="Erfahren Sie mehr über uns und unsere Leistungen." className=""/>
      
    </Layout>
  );
}

export const query = graphql`query IndexQuery {
  heroImage: file(relativePath: {eq: "lighthouse.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allMdxPartner: allMdx(
    filter: {frontmatter: {templateKey: {eq: "partner-page"}}}
  ) {
    nodes {
      frontmatter {
        partnerName
        description
        twitter
        linkedin
        email
        pgp_keyfile {
          publicURL
          name
          extension
        }
        pgp_fingerprint
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
      }
      slug
    }
  }
  allMdxPosts: allMdx(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {frontmatter: {templateKey: {eq: "blog-page"}}}
    limit: 3
  ) {
    nodes {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        authors {
          frontmatter {
            partnerName
          }
        }
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              aspectRatio: 3
              width: 600
              transformOptions: {cropFocus: CENTER}
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      id
      slug
      excerpt(pruneLength: 400)
    }
  }
  allMdxOffers: allMdx(
    filter: {frontmatter: {templateKey: {eq: "angebot-page"}}}
    sort: {fields: frontmatter___order}
  ) {
    nodes {
      frontmatter {
        title
        description
        hero_image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        hero_image_alt
      }
      body
      slug
      excerpt(pruneLength: 100)
    }
  }
  allMdxMedia: allMdx(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {frontmatter: {templateKey: {eq: "media-snippet"}}}
    limit: 3
  ) {
    nodes {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        authors {
          frontmatter {
            partnerName
          }
        }
        url
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 3
              width: 600
              transformOptions: {
                cropFocus: CENTER
              }
            )
          }
        }
      }
      id
      slug
      excerpt(pruneLength: 400)
    }
  }
}
`

export default IndexPage;

/*export async function getServerData() {return {
  props: {
    // the page's props go here
  }
}}*/
